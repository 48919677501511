<template>
        <div class="page-wrapper">
            <Header />

            <div>
                <router-view />
            </div>


            <Footer />
        </div>
</template>

<script>
    import Footer from "./Footer";
    import Header from "./Header";

    export default {
        name: "index",
        components: {
            Footer,
            Header
        },
    }
</script>

<style scoped>

</style>
